import { createRouter, createWebHistory } from 'vue-router';
import { useAuthStore } from '@/store/auth'; // Adjust import based on your store setup
import ServiceView from '@/views/ServiceView.vue';

const HomeView = () => import ('../views/HomeView.vue');
const AboutView = () => import ('../views/AboutView.vue');
const EventView = () => import ('../views/EventView.vue');
const NotFoundView = () => import ('../views/NotFoundView.vue');
const ExploreAfricaView = () => import ('../views/ExploreAfrica.vue');
const BlogView = () => import ('../views/BlogView.vue');
const BlogDetailView = () => import ('../views/BlogDetailView.vue');
const WebApp = () => import ('../views/WebApp.vue');
const LoginView = () => import ('../views/LoginView.vue');
const RegisterView = () => import ('../views/RegisterView.vue');
const BookingView = () => import ('../views/Auth/BookingView.vue');
const RentingView = () => import ('../views/Auth/RentingView.vue');
const PackageView = () => import ('../views/Auth/PackageView.vue');



const routes = [
  {
    path: '/flight-booking',
    name: 'flight-booking',
    component: BookingView,
    meta: { title: 'Unveil-Africa|Booking',requiresAuth: true }
  },
  {
    path: '/hotel-booking',
    name: 'hotel-booking',
    component: BookingView,
    meta: { title: 'Unveil-Africa|Booking',requiresAuth: true }
  },
  {
    path: '/tour-packages',
    name: 'tour-packages',
    component: PackageView,
    meta: { title: 'Unveil-Africa|Tour',requiresAuth: true }
  },
  {
    path: '/car-rental',
    name: 'car-rental',
    component: RentingView,
    meta: { title: 'Unveil-Africa|Renting',requiresAuth: true }
  },  
  {
    path: '/',
    name: 'Home',
    component: HomeView,
    meta: { title: 'Unveil-Africa|Home' }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: { title: 'UnveilAfrica|Login' }
  },
  {
    path: '/register',
    name: 'register',
    component: RegisterView,
    meta: { title: 'UnveilAfrica|Register' }
  },
  {
    path: '/web-app',
    name: 'web-app',
    component: WebApp,
    meta: { title: 'Unveil-Africa|Home Overview of Unveil Africa' }
  },
  {
    path: '/about',
    name: 'About',
    component: AboutView,
    meta: { title: 'Unveil-Africa|About Us Meet the Unveil Afric Team' }
  },
  {
    path: '/contact',
    name: 'Contact',
    component: () => import('@/views/ContactView.vue'),
    meta: { title: 'Unveil-Africa|Contact Us' }
  },
  {
    path: '/explore',
    name: 'explore',
    component: ExploreAfricaView,
    meta: { title: 'Unveil-Africa|Explore The services at unveil africa' }
  },
  {
    path: '/events',
    name: 'Events',
    component: EventView,
    meta: { title: 'Unveil-Africa|Events Our events at unveil africa' }
  },
  {
    path: '/blog',
    name: 'blogs',
    component: BlogView,
    meta: { title: 'Unveil-Africa|Blogs Read more news' }
  },
  {
    path: '/blog/:id',
    name: 'blog',
    component: BlogDetailView,
    meta: { title: 'Blog details' }
  },
  {
    path: '/service/:id',
    name: 'service',
    component: ServiceView,
    meta: { title: '' }
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'NotFound',
    component: NotFoundView,
    meta: { title: 'Page Not Found' }
  }
];

const router = createRouter({
  history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  }
});

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();
  document.title = to.meta.title || 'Default Title';

  if (to.meta.requiresAuth && !authStore.isAuthenticated) {
    // Store the intended route
    authStore.redirectAfterLogin = to.fullPath;
    next({ name: 'login', query: { redirect: to.fullPath } });
  } else {
    next();
  }
});

export default router;
