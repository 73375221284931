<template>
    <SectionCard class="mt-24">
        <div class="w-full max-w-6xl p-8 bg-white dark:bg-gray-800 rounded-lg shadow-md">
            <h1 class="text-2xl font-bold mb-6 text-gray-800 dark:text-gray-100">Service Details</h1>
            <div v-if="service.name">
                <h2 class="text-xl font-semibold mb-4 text-gray-700 dark:text-gray-200">{{ service.name }}</h2>
                <p class="mb-4 text-gray-600 dark:text-gray-300" v-html="service.description"></p>
                <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-4">
                    <div v-for="(attachment, index) in service.attachments" :key="index" :class="attachmentClass(attachment.file)">
                        <template v-if="isImage(attachment.file)">
                            <img :src="attachment.file" alt="Attachment Image" class="w-full h-full object-cover rounded-lg">
                        </template>
                        <template v-else-if="isVideo(attachment.file)">
                            <video controls :src="attachment.file" class="w-full h-full object-cover rounded-lg"></video>
                        </template>
                        <template v-else-if="isAudio(attachment.file)">
                            <audio controls :src="attachment.file" class="w-full h-full rounded-lg"></audio>
                        </template>
                        <template v-else>
                            <a :href="attachment.file" target="_blank" class="block p-4 bg-gray-200 dark:bg-gray-700 rounded-lg text-center">
                                Download Attachment
                            </a>
                        </template>
                    </div>
                </div>
            </div>
            <div v-else>
                <p class="text-gray-700 dark:text-gray-200">Loading service details...</p>
            </div>
        </div>
    </SectionCard>
</template>

<script setup>
import SectionCard from '@/components/sectionCard.vue';
import { ref, onMounted,watch } from 'vue';
import { useRoute } from 'vue-router';
import api from '@/services/api';
import { useFileHelpers } from '@/composables/useFileHelpers';

const service = ref({});
const route = useRoute();
const { isImage, isVideo, isAudio } = useFileHelpers();

const serviceDetails = async () => {
    try {
        const response = await api.getServiceDetails(route.params.id);
        service.value = response.data;
    } catch {
        console.error('Failed to get service details');
    }
};

onMounted(() => {
    serviceDetails();
});

// Watch for changes in blog title and update document title
watch(() => service.value.title, (newTitle) => {
  if (newTitle) {
    document.title = newTitle;
  }
});

const attachmentClass = (attachmentFile) => {
    if (isImage(attachmentFile)) {
        return 'col-span-1';
    } else if (isVideo(attachmentFile)) {
        return 'col-span-2';
    } else if (isAudio(attachmentFile)) {
        return 'col-span-1 sm:col-span-2';
    } else {
        return 'col-span-1';
    }
};
</script>

<style scoped>
/* Adjust this margin based on your navbar height */
.mt-24 {
    margin-top: calc(4rem + var(--navbar-height, 4rem)); /* Fallback to 4rem if --navbar-height is not set */
}
</style>
