import axios from 'axios';

const apiClient = axios.create({
  baseURL: 'https://www.api.unveil-africa.com',
  withCredentials: false, // This is the default
  headers: {
    'Accept': 'application/json',
  }
});

export default {
  getBlogPosts() {
    return apiClient.get('/blog/api/blog-posts');
  },
  getBlogPost(id) {
    return apiClient.get(`/blog/api/blog-posts/${id}`);
  },
  createComment(commentData){
    return apiClient.post('/blog/api/comments/', commentData);
  },
  getComment(id){
    return apiClient.get(`/blog/api/comments/${id}`);
  },
  createReply(replayData){
    return apiClient.post('/blog/api/replies/', replayData);
  },
  createBlogPost(postData) {
    return apiClient.post('/blog/api/blog-posts/', postData);
  },
  updateBlogPost(id, postData) {
    return apiClient.put(`/blog/api/blog-posts/${id}`, postData);
  },
  deleteBlogPost(id) {
    return apiClient.delete(`/blog/api/blog-posts/${id}`);
  },
  subscribeNewsletter(email) {
    return apiClient.post('/api/newsletter-subscriptions/', email);
  },
  contactUs(contactInfo) {
    return apiClient.post('/api/contact-messages/', contactInfo);
  },
  getTestimonials() {
    return apiClient.get('/api/testimonials');
  },
  getServices() {
    return apiClient.get('/api/services');
  },
  getServiceDetails(id) {
    return apiClient.get(`/api/services/${id}`);
  },
  getEvents() {
    return apiClient.get('/api/events');
  },
  testimonialSave(testimonialData) {
    return apiClient.post('/api/testimonials/', testimonialData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    });
  },
  trackView(id) {
    return apiClient.post(`/blog/api/blog-posts/${id}/track_view/`);
  }
};
