<template>
  <footer class="footer-bg text-center text-[#a0aec0] py-12">
    <div class="container md:w-[90%] mx-auto p-4 w-full">
      <!-- <PartnerCom /> -->
      <div class="grid grid-cols-1 md:grid-cols-4 gap-8">
        <!-- Quick Links Section -->
        <div class="quick-links">
          <h1 class="text-xl font-semibold mb-4">Quick Links</h1>
          <div class="h-1 w-10 bg-primary mb-4"></div>
          <ul class="space-y-2">
            <li><a href="/about" class="footer-link">About Us</a></li>
            <li><a href="/events" class="footer-link">Events</a></li>
            <li><a href="/explore" class="footer-link">Explore Africa</a></li>
            <li><a href="/blog" class="footer-link">Blog</a></li>
            <li><a href="/contact" class="footer-link">Contact Us</a></li>
          </ul>
        </div>
        <!-- Social Media Icons Section -->
        <div class="social-media mx-auto">
          <h1 class="text-xl font-semibold mb-4">Follow Us</h1> 
          <div class="h-1 w-10 bg-primary mb-4"></div>
          <div class="flex icons mx-auto space-x-4">
            <li><a href="https://www.tiktok.com/@unveilafrica?_t=8oHj7GFPOxO&_r=1" class="text-gray-400 hover:text-primary"><i class="fab fa-tiktok"></i></a></li>
            <li><a href="https://x.com/UnveilAfrica15?t=lLspIjh7U23T9CDuicemOg&s=09" class="text-gray-400 hover:text-primary"><svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="25" height="25" viewBox="0 0 50 50">
<path d="M 5.9199219 6 L 20.582031 27.375 L 6.2304688 44 L 9.4101562 44 L 21.986328 29.421875 L 31.986328 44 L 44 44 L 28.681641 21.669922 L 42.199219 6 L 39.029297 6 L 27.275391 19.617188 L 17.933594 6 L 5.9199219 6 z M 9.7167969 8 L 16.880859 8 L 40.203125 42 L 33.039062 42 L 9.7167969 8 z"></path>
</svg></a></li>
            <li><a href="https://www.instagram.com/unveil_african15?igsh=ZGtieXpnem9zc2Nu" class="text-gray-400 hover:text-primary"><i class="fab fa-instagram"></i></a></li>
            <li><a href="https://www.linkedin.com/company/unveil-africa/" class="text-gray-400 hover:text-primary"><i class="fab fa-linkedin-in"></i></a></li>
          </div>
        </div>
        <!-- Contact Information Section -->
        <div class="contact-info">
          <h1 class="text-xl font-semibold mb-4">Contact Information</h1>
          <div class="h-1 w-10 bg-primary mb-4"></div>
          <p class="mb-4">Email: <a href="mailto:contact@Unveil-africa.com">contact@Unveil-africa.com</a></p>
          <p class="mb-4">Phone: +250 781690663</p>
          <p class="mb-4">Address: KK 669 st, Kigali, Rwanda</p>
        </div>
        <!-- Newsletter Signup Section -->
        <div class="newsletter">
          <h2 class="text-xl font-semibold mb-4">Newsletter</h2>
          <div class="h-1 w-10 bg-primary mb-4"></div>
          <p class="mb-4">Subscribe to our Newsletter to get the latest updates.</p>
          <form @submit.prevent="subscribe">
            <input type="email" v-model="email" placeholder="Email" class="bg-gray-800 text-[#a0aec0] py-2 px-4 w-full mb-2" required>
            <button :disabled="isSubmitting" class="py-2 px-4 bg-primary hover:bg-secondary text-black w-full">
              <span class="" v-if="isSubmitting">Subscribing</span>
              <span v-else>Subscribe</span>
            </button>
          </form>
        </div>
      </div>
      <div class="mt-8 border-t border-gray-700 pt-4 flex flex-col md:flex-row justify-between text-gray-400 text-sm">
        <p>&copy; {{year}} Unveil-Africa. All rights reserved. Design by <a href="#" class="text-primary hover:underline">Germain a.k.a Syncher</a>.</p>
        <div class="flex space-x-4 mt-2 md:mt-0">
          <a href="#" class="hover:text-primary">Privacy Policy</a>
          <a href="#" class="hover:text-primary">Terms of Service</a>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
// import PartnerCom from '@/components/PartnerCom.vue';
import api from '@/services/api';
import { useToastStore } from '@/store/toast';


export default {
  components: {
    // PartnerCom,
  },
  data() {
    return {
      email: '',
      year: new Date().getFullYear(),
      isSubmitting: false,
    };
  },
  methods: {
    async subscribe() {
      this.isSubmitting = true;
      const toastStore = useToastStore();

      try {
        await api.subscribeNewsletter({ email: this.email });
        toastStore.showToast(4000, 'Subscription successful! You will occasionally receive our mails', 'bg-secondary');
      } catch (error) {
        toastStore.showToast(4000, 'Failed to subscribe. Please try again later.', 'bg-red-500');
      } finally{
        this.isSubmitting = false;
      }
    }
  },
  name: 'FooterCom',
};
</script>

<style scoped>
.footer-bg {
  background-color: black; /* Example background color */
  clip-path: polygon(0 0, 25% 10%, 50% 0, 75% 10%, 100% 0, 100% 100%, 0 100%);
}
.footer-link {
  color: #a0aec0; /* Example link color */
  transition: color 0.3s;
}
.footer-link:hover {
  color: #9f7aea; /* Example hover color */
}
.quick-links, .social-media, .contact-info, .newsletter {
  background: rgba(255, 255, 255, 0.1); /* Transparent background */
  padding: 20px;
  border-radius: 15px; /* Rounded corners for a liquid look */
  backdrop-filter: blur(10px); /* Blur effect for a frosted glass appearance */
}
</style>
