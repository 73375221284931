<template>
    <div class="section-card shadow-sm  min-h-screen md:p-8 p-4 w-full md:w-[90%] mx-auto bg-light dark:bg-dark transition duration-500 ease-in-out flex flex-col justify-center items-center">
        <slot />
    </div>
  </template>
  
  <script>
  export default {
    name: 'SectionCard'
  }
  </script>
  
  <style scoped>
  .section-card {
    transition: background-color 0.3s, box-shadow 0.3s;
  }
  </style>
  