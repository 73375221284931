import { defineStore } from 'pinia';
import axios from '@/axios';
import { useToastStore } from './toast';

export const useAuthStore = defineStore('auth', {
  state: () => ({
    accessToken: localStorage.getItem('accessToken') || '',
    refreshToken: localStorage.getItem('refreshToken') || '',
    user: null,
    error: null,
  }),
  getters: {
    isAuthenticated: (state) => !!state.accessToken && !!state.user,
  },
  actions: {
    async login(username, password) {
      this.error = null; // Reset error before new login attempt
      const toastStore = useToastStore();

      try {
        const response = await axios.post('/api/token/', { username, password });

        if (response.status === 200) {
          this.accessToken = response.data.access;
          this.refreshToken = response.data.refresh;
          localStorage.setItem('accessToken', this.accessToken);
          localStorage.setItem('refreshToken', this.refreshToken);
          await this.fetchUser();

          toastStore.showToast(3000, 'Login successful!', 'bg-secondary');
        } else {
          throw new Error('Login not successful');
        }
      } catch (error) {
        this.error = error.response?.data?.detail || error.message; // Populate error with the error message
        toastStore.showToast(3000, `${this.error}`, 'bg-red-700');
        console.error('Error during login:', error);
      }
    },

    async register(username, email, password) {
      this.error = null;
      const toastStore = useToastStore();

      try {
        const response = await axios.post('/api/register/', { username, email, password });

        if (response.status === 201) {
          // Optionally, you can automatically log in the user after successful registration
          toastStore.showToast(3000, 'Registration successful!', 'bg-secondary');
        } else {
          throw new Error('Registration not successful');
        }
      } catch (error) {
        this.error = error.response?.data?.detail || error.message; // Populate error with the error message
        toastStore.showToast(3000, `${this.error}`, 'bg-red-700');
        console.error('Error during registration:', error);
      }
    },
    
    async fetchUser() {
      if (this.accessToken) {
        try {
          const response = await axios.get('/api/user/', {
            headers: {
              Authorization: `Bearer ${this.accessToken}`,
            },
          });
          this.user = response.data;
        } catch (error) {
          this.error = error.response?.data?.detail || error.message;
          console.error('Error fetching user:', error);
        }
      }
    },
    
    logout() {
      this.accessToken = '';
      this.refreshToken = '';
      this.user = null;
      localStorage.removeItem('accessToken');
      localStorage.removeItem('refreshToken');

      const toastStore = useToastStore();
      toastStore.showToast(3000, 'Logged out successfully!', 'bg-secondary');
    },
  },
  persist: {
    storage: localStorage,
  },
});
